import { render, staticRenderFns } from "./WriterRoute.vue?vue&type=template&id=37304f27&scoped=true&"
import script from "./WriterRoute.vue?vue&type=script&lang=js&"
export * from "./WriterRoute.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37304f27",
  null
  
)

export default component.exports