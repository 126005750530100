<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <img
      ref="logo"
      class="z-20 absolute top-4 md:top-12 left-3 md:left-10 w-40 md:w-64 transform transform-gpu -translate-y-64"
      src="~@/assets/images/future-news-logo.svg"
      alt="Irish Aid Future News"
    />
  </transition>
</template>
<script>
import { gsap } from 'gsap';
export default {
  methods: {
    enter(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.logo,
          {
            y: '-256', // h-64, 16rem 256px
            autoAlpha: 0,
            duration: 0.5,
            delay: 1.5,
          },
          { autoAlpha: 1, y: '0', duration: 0.5, delay: 1.5 }
        );
    },
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.logo,
          { autoAlpha: 1, y: '0', duration: 0.5, delay: 1.5 },
          {
            y: '-256', // h-64, 16rem 256px
            autoAlpha: 0,
            duration: 0.5,
            delay: 1.5,
          }
        );
    },
  },
};
</script>

<style scoped></style>
