import axios from 'axios';

import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import VueDebounce from 'vue-debounce';
import Meta from 'vue-meta';
import VueAxios from 'vue-axios';

import 'tailwindcss/tailwind.css';
import './assets/styles/index.css';

import TitleRoute from './routes/TitleRoute';
import IntroRoute from './routes/IntroRoute';
import BackgroundRoute from './routes/BackgroundRoute';
import LoadingRoute from './routes/LoadingRoute';
import WriterRoute from './routes/WriterRoute';
import StoryRoute from './routes/StoryRoute';
import HeadlineRoute from './routes/HeadlineRoute';
import RecordRoute from '@/routes/RecordRoute';
import OutroRoute from '@/routes/OutroRoute';

import store from './store';

// Register Greensock plugins
import { gsap } from 'gsap';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { CustomEase } from 'gsap/CustomEase';
import Draggable from 'gsap/Draggable';

gsap.registerPlugin(InertiaPlugin);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(Draggable);

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(VueDebounce);
Vue.use(VueAxios, axios);

const routes = [
  { path: '/', component: LoadingRoute, name: 'loading' },
  {
    path: '/',
    component: BackgroundRoute,
    name: 'app',
    children: [
      { path: 'hello', component: TitleRoute, name: 'title' },
      { path: 'welcome', component: IntroRoute, name: 'intro' },
      { path: 'write-your-story', component: WriterRoute, name: 'write' },
      { path: 'read-your-story', component: StoryRoute, name: 'story' },
      { path: 'write-a-headline', component: HeadlineRoute, name: 'headline' },
      { path: 'record-yourself', component: RecordRoute, name: 'record' },
      { path: 'bye', component: OutroRoute, name: 'outro' },
    ],
  },
];

const router = new VueRouter({
  routes,
});

new Vue({
  render: (h) => h(App),
  store,
  router,
}).$mount('#app');
