<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <RouteWrapper>
      <section
        ref="container"
        class="z-10 w-full h-full flex justify-center items-center"
      >
        <div class="container flex flex-col items-center">
          <h2
            class="text-white font-stanton text-center text-2xl md:text-4xl mb-8"
          >
            Fill in the blanks to generate a News story
          </h2>
          <div
            class="z-0 w-full md:w-10/12 mx-auto bg-ia-orange shadow-ia-white p-8 rounded-2xl"
          >
            <div
              class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-6"
              v-for="(group, i) in groupedQuestions"
              :key="i"
            >
              <div
                class="flex flex-col items-center"
                :class="[i === currentPage ? 'block' : 'hidden']"
                v-for="(question, j) in group"
                :key="j"
              >
                <input
                  ref="input"
                  class="w-full block rounded-sm border-0 bg-white p-1 md:p-3 text-lg text-black mb-2 md:mb-3 focus:outline-none focus:ring focus:border-ia-orange"
                  :value="answers[currentPage * paginateBy + j]"
                  @input="
                    addAnswer({
                      index: currentPage * paginateBy + j,
                      value: $event.target.value,
                    })
                  "
                />
                <div
                  class="w-10/12 text-sm md:text-base text-white text-center leading-tight"
                  v-html="question.id + ': ' + question.label"
                ></div>
              </div>
            </div>
          </div>
          <div
            class="z-10 w-10/12 mx-auto flex flex-row justify-between transform transform-gpu -translate-y-10"
          >
            <div>
              <Button :color="'bright-blue'" @click="previous()"
                ><span v-if="showPrevious">Previous</span
                ><span v-else>Back</span></Button
              >
            </div>
            <div>
              <Button v-if="showNext" :color="'bright-blue'" @click="next()"
                >Next</Button
              >
              <Button v-else-if="isComplete" :color="'pink'" @click="save()"
                >Submit</Button
              >
            </div>
          </div>
        </div>
        <!-- <ul class="bg-white p-3 text-xs fixed top-0 left-0">
          <li>Current Page: {{ currentPage }}</li>
          <li>Paginate By: {{ paginateBy }}</li>
          <li>Total Questions: {{ totalQuestions }}</li>
          <li>Total Pages: {{ totalPages }}</li>
          <li>Slice start: {{ sliceStart }}</li>
          <li>Slice end: {{ sliceEnd }}</li>
          <li><button @click="reset">reset</button></li>
          <ul>
            <li v-for="(answer, i) in answers" :key="i">
              {{ i }}:{{ answer }}
            </li>
          </ul>
        </ul> -->
      </section>
    </RouteWrapper>
  </transition>
</template>

<script>
/**
 * Writer Route
 *
 * This route allows the user to input all of the words for the story. There
 * are multiple "pages" to this route that must be scrolled through.
 */
import RouteWrapper from '@/components/RouteWrapper';
import { mapGetters, mapActions } from 'vuex';
import { chunk } from '@/utils';
import { gsap } from 'gsap';
import Button from '@/components/Button';

export default {
  data() {
    return {
      currentPage: 0,
      paginateBy: 6,
    };
  },
  components: {
    RouteWrapper,
    Button,
  },
  methods: {
    enter(el, done) {
      this.$root.$emit('show-primary-logo');
      this.$root.$emit('show-secondary-logo');
      this.$root.$emit('show-sunshine-background');

      // Add entering animations
      gsap
        .timeline({
          onComplete: done,
          delay: 0.5,
        })
        .fromTo(
          this.$refs.container,
          {
            opacity: 0,
            y: '-5%',
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            ease: 'power1',
          }
        );
    },
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.container,
          {
            opacity: 1,
            y: 0,
          },
          {
            opacity: 0,
            y: '200%',
            duration: 0.25,
            ease: 'power1.out',
          },
          0
        );
    },
    previous() {
      if (this.currentPage > 0) {
        this.currentPage--;
      } else {
        this.$router.replace({ name: 'intro' });
      }
    },
    next() {
      if (this.validate()) {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      } else {
        console.error('Not valid');
      }
    },
    /**
     * Validate
     *
     * Make sure all text boxes (on this page only) are valid, and notify
     * the user if not by shaking the invalid boxes via GSAP.
     */
    validate() {
      const validationResults = this.answers
        .slice(this.sliceStart, this.sliceEnd)
        .map((answer) => {
          return (
            typeof answer !== 'undefined' &&
            answer.length > 0 &&
            answer.length < 300
          );
        });
      const isValid = validationResults.reduce((result, answer, i) => {
        return result && answer;
      }, true);

      // Jingle jangle
      const invalidInputs = this.$refs.input
        .slice(this.sliceStart, this.sliceEnd)
        .filter((el, i) => {
          return validationResults[i] === false;
        });
      gsap.to(invalidInputs, 0.1, {
        rotate: '+=2',
        repeat: 3,
        yoyo: true,
        ease: 'power1.out',
      });

      return isValid;
    },
    ...mapActions('story', {
      addAnswer: 'addAnswer',
      reset: 'reset',
    }),
    save() {
      this.$router.replace({ name: 'story' });
    },
  },
  computed: {
    ...mapGetters('story', {
      questions: 'questions',
      answers: 'answers',
    }),
    sliceStart() {
      return this.currentPage * this.paginateBy;
    },
    sliceEnd() {
      let end = this.sliceStart + this.paginateBy;
      if (end >= this.totalQuestions) {
        end = this.totalQuestions - 1;
      }
      return end;
    },
    groupedQuestions() {
      return chunk(this.questions, this.paginateBy);
    },
    currentQuestions() {
      return this.questions.slice(this.sliceStart, this.sliceEnd);
    },
    totalQuestions() {
      return this.questions.length;
    },
    totalPages() {
      let total = 0;
      if (this.totalQuestions % this.paginateBy > 0) {
        total++;
      }
      return total + Math.floor(this.totalQuestions / this.paginateBy);
    },
    showNext() {
      return this.currentPage < this.totalPages - 1;
    },
    showPrevious() {
      return this.currentPage > 0;
    },
    isComplete() {
      return this.answers
        .map((answer) => {
          return typeof answer !== 'undefined' && answer.length < 300;
        })
        .reduce((result, answer, i) => {
          return result && answer;
        }, true);
    },
  },
};
</script>

<style scoped></style>
