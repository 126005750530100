<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <img
      ref="logo"
      class="hidden md:block absolute z-20 top-12 right-10 w-52 transform -translate-y-64"
      src="~@/assets/images/our-world-logo-colour.png"
      alt="Irish Aid Our World"
    />
  </transition>
</template>
<script>
import { gsap } from 'gsap';
export default {
  methods: {
    enter(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.logo,
          {
            y: '-256', // h-64, 16rem 256px
            autoAlpha: 0,
            duration: 0.5,
            delay: 1.5,
          },
          { autoAlpha: 1, y: '0', duration: 0.5, delay: 1.5 }
        );
    },
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.logo,
          { autoAlpha: 1, y: '0', duration: 0.5, delay: 1.5 },
          {
            y: '-256', // h-64, 16rem 256px
            autoAlpha: 0,
            duration: 0.5,
            delay: 1.5,
          }
        );
    },
  },
};
</script>

<style scoped></style>
