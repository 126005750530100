<template>
  <main>
    <div class="fixed w-full h-full max-h-full max-w-full overflow-hidden z-0">
      <WorldBackground class="z-0" />
      <SunshineBackground v-if="showSunshineBackground" />
      <PrimaryLogo v-if="showPrimaryLogo" />
      <SecondaryLogo v-if="showSecondaryLogo" />
      <SecondaryInvertedLogo v-if="showSecondaryInvertedLogo" />
    </div>
    <div
      class="flex justicy-center items-center h-full w-full max-h-full max-w-full overflow-hidden z-10"
    >
      <RouterView></RouterView>
    </div>
  </main>
</template>

<script>
/**
 * Background Route
 *
 * This is a wrapper route to provide the background for the background and act as
 * a "parent" route to the rest of the pages so that we can transition from
 * a loading screen.
 *
 * FIXME: This probably isn't the best way to handle the ability to show/hide
 * these logos. Alternative might be to turn them into their own components
 * and use variables to switch on/off. Or use Vuex.
 */
import WorldBackground from '@/components/WorldBackground';
import SunshineBackground from '@/components/SunshineBackground';
import PrimaryLogo from '@/components/PrimaryLogo';
import SecondaryLogo from '@/components/SecondaryLogo';
import SecondaryInvertedLogo from '@/components/SecondaryInvertedLogo';

export default {
  data() {
    return {
      showPrimaryLogo: false,
      showSecondaryLogo: false,
      showSecondaryInvertedLogo: false,
      showWorldBackground: true,
      showSunshineBackground: false,
    };
  },
  components: {
    WorldBackground,
    SunshineBackground,
    PrimaryLogo,
    SecondaryLogo,
    SecondaryInvertedLogo,
  },
  created() {
    this.$root.$on('show-primary-logo', () => {
      if (this.showPrimaryLogo == false) {
        this.showPrimaryLogo = true;
      }
    });
    this.$root.$on('hide-primary-logo', () => {
      if (this.showPrimaryLogo == true) {
        this.showPrimaryLogo = false;
      }
    });

    this.$root.$on('show-secondary-logo', () => {
      if (this.showSecondaryLogoInverted == true) {
        this.showSecondaryInvertedLogo = false;
      }
      if (this.showSecondaryLogo == false) {
        this.showSecondaryLogo = true;
      }
    });
    this.$root.$on('hide-secondary-logo', () => {
      if (this.showSecondaryLogo == true) {
        this.showSecondaryLogo = false;
      }
    });

    this.$root.$on('show-secondary-logo-inverted', () => {
      if (this.showSecondaryLogo == true) {
        this.showSecondaryLogo = false;
      }
      if (this.showSecondaryInvertedLogo == false) {
        this.showSecondaryInvertedLogo = true;
      }
    });
    this.$root.$on('hide-secondary-logo-inverted', () => {
      if (this.showSecondaryLogoInverted == true) {
        this.showSecondaryLogoInverted = false;
      }
    });

    this.$root.$on('show-sunshine-background', () => {
      if (this.showSunshineBackground == false) {
        this.showSunshineBackground = true;
      }
    });
    this.$root.$on('hide-sunshine-background', () => {
      if (this.showSunshineBackground == true) {
        console.log('HIDING');
        this.showSunshineBackground = false;
      }
    });
  },
};
</script>

<style scoped></style>
