<template>
  <transition appear :css="false" @enter="enter">
    <RouteWrapper>
      <section class="container mx-auto z-10 flex justify-center">
        <div
          class="z-10 flex flex-col justify-center items-center w-11/12 md:w-6/12 lg:w-6/12 transform -translate-y-1/4 md:translate-y-0 lg:-translate-x-1/2"
        >
          <div class="relative z-0 w-full mx-auto p-4 md:p-6" ref="content">
            <h1
              class="text-white font-stanton text-center text-2xl md:text-4xl mb-3"
            >
              Write Your Headline
            </h1>
            <p class="text-white text-center text-base lg:text-xl mb-3 md:mb-6">
              Submit your breaking news headline for a chance to feature in the
              Global Goal Getters online magazines, by kids for kids!
            </p>
            <p class="text-white text-center text-base lg:text-xl mb-3 md:mb-6">
              Write your own 2022 <strong>breaking news headline</strong> about
              something that could happen or is happening to help create a
              better world:
            </p>
            <form ref="form">
              <input
                class="w-full rounded-md border-0 bg-white p-2 lg:p-3 text-lg text-black placeholder-gray-400 mb-3 focus:outline-none focus:ring focus:border-ia-orange"
                name="email"
                id="email"
                v-model="email"
                placeholder="Your email address"
                required
              />
              <textarea
                ref="textarea"
                class="w-full rounded-md border-0 bg-white p-2 lg:p-3 text-lg text-black placeholder-gray-400 mb-3 focus:outline-none focus:ring focus:border-ia-orange"
                name="content"
                id="content"
                rows="5"
                v-model="content"
                placeholder="Your news headline"
                required
              ></textarea>
              <div
                class="absolute bottom-0 right-1/2 md:right-0 transform translate-x-1/2 md:translate-x-0 translate-y-full md:translate-y-2/4"
              >
                <Button :size="'small'" @click.prevent="submit">
                  Submit
                </Button>
                <input type="submit" @click.prevent="submit" />
              </div>
            </form>
            <div
              class="background-1 absolute top-0 left-0 w-full h-full bg-ia-orange rounded-2xl"
            >
              &nbsp;
            </div>
            <div
              class="background-2 absolute top-0 left-0 w-full h-full bg-white rounded-2xl"
            >
              &nbsp;
            </div>
          </div>
        </div>
        <img
          ref="character"
          class="absolute bottom-6 md:bottom-10 lg:bottom-20 z-0 right-0 h-64 sm:h-64 md:h-96 lg:h-160 xl:h-176"
          src="~@/assets/images/character-b.png"
        />
      </section>
      <div ref="ticker"><Ticker :text="content"></Ticker></div>
    </RouteWrapper>
  </transition>
</template>

<script>
/**
 * Headline Route
 *
 * This route allows users to input a news headline in a large input then have
 * that headline scroll along the bottom of the page like a news headline
 */
import RouteWrapper from '@/components/RouteWrapper';
import Button from '@/components/Button';
import Ticker from '@/components/Ticker';
import { gsap } from 'gsap';

export default {
  components: {
    RouteWrapper,
    Button,
    Ticker,
  },
  data() {
    return {
      errors: [],
      email: null,
      content: null,
    };
  },
  methods: {
    submit($event) {
      let formData = new FormData();
      formData.append('email', this.email);
      formData.append('content', this.content);
      formData.append('form-name', 'submit-story');
      this.axios.post('/', formData).then(() => {
        this.$router.push({ name: 'record' });
      });
      $event.preventDefault();
    },
    enter(el, done) {
      this.$root.$emit('show-primary-logo');
      this.$root.$emit('show-secondary-logo');
      this.$root.$emit('show-sunshine-background');
      this.$refs.textarea.focus();
      gsap
        .timeline({
          onComplete: done,
          delay: 1,
        })
        .fromTo(
          this.$refs.ticker,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.5,
          }
        )
        .fromTo(
          this.$refs.character,
          {
            opacity: 0,
            y: '100%',
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.75,
          }
        )
        .fromTo(
          this.$refs.content,
          {
            opacity: 0,
            y: '10%',
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.75,
          }
        );
    },
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.content,
          {
            y: 0,
          },
          {
            y: '200%',
            duration: 0.5,
          },
          0
        )
        .fromTo(
          this.$refs.ticker,
          {
            opacity: 1,
          },
          {
            opacity: 0,
            duration: 0.25,
          },
          0
        )
        .fromTo(
          this.$refs.character,
          {
            y: 0,
          },
          {
            y: '200%',
            duration: 0.5,
          },
          0
        );
    },
  },
};
</script>

<style scoped>
.background-1 {
  transform: skew(-0.001turn, -1deg);
  z-index: -1;
}

.background-2 {
  transform: skew(-0.001turn, -1.5deg) translate3d(-10px, -10px, 0);
  z-index: -2;
}
</style>
