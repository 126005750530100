<template>
  <transition appear :css="false">
    <section class="fixed w-full z-20 bottom-0 left-0 bg-white flex flex-col">
      <div class="relative w-full bg-white">
        <div class="relative flex items-center h-auto p-2 lg:p-4">
          <img
            class="hidden md:block h-8 lg:h-12 w-auto mr-3"
            src="@/assets/images/live-text.png"
          />
          <div class="headline-marquee relative">
            <div
              class="hidden md:block headline-gradient z-10 absolute top-0 left-0 h-full w-10"
            >
              &nbsp;
            </div>
            <MarqueeText
              class="font-din-pro text-base md:text-2xl"
              :duration="20"
              :repeat="3"
              v-if="text"
              >{{ formattedText }}
            </MarqueeText>
          </div>
        </div>
        <Clock></Clock>
      </div>
      <!-- <div
        class="bg-ia-navy text-xs md:text-sm lg:text-base w-full text-white flex md:px-2 lg:px-6 py-2"
      >
        <h2 class="hidden md:block w-3/12 mr-4">
          UT NIHIL REGIONE DESERUISSE:
        </h2>
        <div class="relative w-full md:w-9/12">
          <div
            class="hidden md:block marquee-gradient z-10 absolute top-0 left-0 h-full w-10"
          >
            &nbsp;
          </div>
          <MarqueeText class="w-full">
            <ul class="relative flex">
              <li class="flex justify-center items-center mr-6">
                <img src="@/assets/images/up-arrow.png" class="h-2 mr-1" /><span
                  >1 SAMPLE TEXT HERE</span
                >
              </li>
              <li class="flex justify-center items-center mr-6">
                <img
                  src="@/assets/images/down-arrow.png"
                  class="h-2 mr-1"
                /><span>2 SAMPLE TEXT HERE</span>
              </li>
              <li class="flex justify-center items-center mr-6">
                <img src="@/assets/images/up-arrow.png" class="h-2 mr-1" /><span
                  >SAMPLE TEXT HERE</span
                >
              </li>
              <li class="flex justify-center items-center mr-6">
                <img src="@/assets/images/up-arrow.png" class="h-2 mr-1" /><span
                  >3 SAMPLE TEXT HERE</span
                >
              </li>
              <li class="flex justify-center items-center mr-6">
                <img src="@/assets/images/up-arrow.png" class="h-2 mr-1" /><span
                  >4 SAMPLE TEXT HERE</span
                >
              </li>
              <li class="flex justify-center items-center mr-6">
                <img
                  src="@/assets/images/down-arrow.png"
                  class="h-2 mr-1"
                /><span>5 SAMPLE TEXT HERE</span>
              </li>
              <li class="flex justify-center items-center mr-6">
                <img src="@/assets/images/up-arrow.png" class="h-2 mr-1" /><span
                  >6 SAMPLE TEXT HERE</span
                >
              </li>
              <li class="flex justify-center items-center mr-6">
                <img src="@/assets/images/up-arrow.png" class="h-2 mr-1" /><span
                  >7 SAMPLE TEXT HERE</span
                >
              </li>
              <li class="flex justify-center items-center mr-6">
                <img src="@/assets/images/up-arrow.png" class="h-2 mr-1" /><span
                  >8 SAMPLE TEXT HERE</span
                >
              </li>
              <li class="flex justify-center items-center mr-6">
                <img
                  src="@/assets/images/down-arrow.png"
                  class="h-2 mr-1"
                /><span>9 SAMPLE TEXT HERE</span>
              </li>
              <li class="flex justify-center items-center mr-6">
                <img src="@/assets/images/up-arrow.png" class="h-2 mr-1" /><span
                  >10 SAMPLE TEXT HERE</span
                >
              </li>
              <li class="flex justify-center items-center mr-6">
                <img src="@/assets/images/up-arrow.png" class="h-2 mr-1" /><span
                  >11 SAMPLE TEXT HERE</span
                >
              </li>
            </ul>
          </MarqueeText>
        </div>
      </div> -->
    </section>
  </transition>
</template>
<script>
import MarqueeText from 'vue-marquee-text-component';
import Clock from '@/components/Clock';
export default {
  components: {
    MarqueeText,
    Clock,
  },
  props: {
    text: {
      type: String,
    },
  },
  computed: {
    formattedText() {
      if (this.text.charAt(this.text.length - 1) != '.') {
        return `${this.text.trim()}.`;
      } else {
        return this.text.trim();
      }
    },
  },
};
</script>

<style scoped>
.headline-marquee {
  min-height: 1.5rem;
}

.marquee-gradient {
  background: linear-gradient(
    90deg,
    rgba(0, 11, 74, 1) 32%,
    rgba(0, 11, 74, 0) 100%
  );
}

.headline-gradient {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 32%,
    rgba(0, 0, 0, 0) 100%
  );
}
</style>
