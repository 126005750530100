<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <div class="absolute z-0 w-full h-full top-0 left-0">
      <img
        ref="sun"
        class="sun z-40 absolute top-0 left-1/2 w-40 md:90 lg:w-96 transform"
        src="@/assets/images/sun.png"
      />
      <img
        ref="hotair1"
        class="hotair1 z-30 absolute top-44 left-1/2 w-12 md:w-24"
        src="@/assets/images/hot-air-1.png"
      />
      <img
        ref="hotair2"
        class="hotair2 z-30 absolute top-32 left-1/2 w-10 md:w-20"
        src="@/assets/images/hot-air-2.png"
      />
      <img
        ref="cloud1"
        class="cloud1 z-30 absolute top-24 left-1/2 w-24 md:w-28"
        src="@/assets/images/cloud-1.png"
      />
      <img
        ref="cloud2"
        class="cloud2 z-30 absolute top-32 left-1/2 w-24 md:w-32"
        src="@/assets/images/cloud-2.png"
      />
      <img
        ref="sunshine"
        class="sunshine z-20 absolute w-full h-3/6 md:h-auto object-cover md:object-fit bottom-0 left-0 object-cover"
        src="@/assets/images/sunshine-background.png"
      />
      <img
        ref="background"
        class="background z-10 absolute w-full h-full top-0 left-0 object-cover"
        src="@/assets/images/blue-sky-background.jpg"
      />
    </div>
  </transition>
</template>
<script>
import { gsap } from 'gsap';

export default {
  methods: {
    enter(el, done) {
      gsap
        .timeline({
          onComplete: done,
          delay: 0.5,
        })
        .fromTo(
          this.$refs.background,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.5,
          },
          0
        )
        .fromTo(
          this.$refs.sun,
          {
            y: '-200%',
          },
          {
            y: 0,
            duration: 0.5,
            ease: 'power1',
          },
          0
        )
        .fromTo(
          this.$refs.cloud1,
          {
            y: '-400%',
          },
          {
            y: 0,
            duration: 0.5,
            ease: 'power1',
          },
          1
        )
        .fromTo(
          this.$refs.cloud2,
          {
            y: '-400%',
          },
          {
            y: 0,
            duration: 0.5,
            ease: 'power1',
          },
          0.5
        )
        .fromTo(
          this.$refs.hotair1,
          {
            y: '-400%',
          },
          {
            y: 0,
            duration: 1,
          },
          1
        )
        .fromTo(
          this.$refs.hotair2,
          {
            y: '-400%',
          },
          {
            y: 0,
            duration: 1,
          },
          1
        )
        .fromTo(
          this.$refs.sunshine,
          {
            y: '500%',
          },
          {
            y: 0,
            duration: 1,
          },
          1
        );
    },
    leave(el, done) {},
  },
};
</script>

<style scoped>
.sun {
  transform: translate3d(-50%, -200%, 0); /* staring position for gsap */
}

.cloud1 {
  left: 75%;
  transform: translate3d(-50%, 0, 0); /* staring position for gsap */
}

.cloud2 {
  left: 25%;
  transform: translate3d(-50%, 0, 0); /* staring position for gsap */
}

.hotair1 {
  left: 70%;
  transform: translate3d(-50%, 0, 0); /* staring position for gsap */
}

.hotair2 {
  left: 80%;
  transform: translate3d(-50%, 0, 0); /* staring position for gsap */
}
</style>
