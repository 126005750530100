<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <RouteWrapper>
      <section class="container mx-auto z-10 flex justify-center">
        <div class="w-10/12 md:w-6/12 lg:transform-gpu lg:translate-x-1/2">
          <div ref="speechBubble">
            <SpeechBubble>
              <div
                class="text-white font-din-pro text-center text-base lg:text-lg xl:text-xl mb-3 md:mb-6 md:mx-10 md:mt-10"
              >
                <p>
                  Why not record yourself reading your future news report? You
                  could add news from the present (2022). This could be about
                  something that is happening or should be happening right now
                  to help create a better world in the future.
                </p>
                <p class="mt-3">
                  Then, send your recording to us as an entry for the 2022 Our
                  World Irish Aid Awards. And who knows, your future news report
                  might appear in one of our Global Goal Getters online
                  magazines, by kids, for kids.
                </p>
                <p class="mt-3">
                  You can email your recording to us to
                  <a
                    class="underline"
                    href="mailto:ourworld@realnation.ie"
                    target="_blank"
                    >ourworld@realnation.ie</a
                  >
                  or submit your recording on
                  <a
                    class="underline"
                    target="_blank"
                    href="https://www.ourworldirishaidawards.ie/form-submission/"
                    >our website</a
                  >
                  here.
                </p>
                <p class="mt-3">
                  Make sure you ask your teacher or a parent/guardian to fill in
                  your
                  <a
                    class="underline"
                    target="_blank"
                    href="https://www.ourworldirishaidawards.ie/wp-content/uploads/2021/11/Irish-Aid-2022-Entry-Form_Interactive.pdf"
                    >Entry Form</a
                  >
                  and send it in with your submission!
                </p>
              </div>
              <div ref="startButton">
                <Button @click="submit()">Submit</Button>
              </div>
              <div>
                <button
                  @click="next()"
                  class="text-white font-stanton text-2xl"
                >
                  Next
                </button>
              </div>
            </SpeechBubble>
          </div>
        </div>
        <img
          ref="character"
          class="absolute bottom-0 z-10 left-0 h-40 sm:h-64 md:h-96 lg:h-160 xl:h-176"
          src="~@/assets/images/character-b.png"
        />
      </section>
    </RouteWrapper>
  </transition>
</template>

<script>
import { gsap } from 'gsap';
import Button from '@/components/Button';
import RouteWrapper from '@/components/RouteWrapper';
import SpeechBubble from '@/components/SpeechBubble';

export default {
  components: {
    Button,
    RouteWrapper,
    SpeechBubble,
  },
  methods: {
    next() {
      this.$store.dispatch('story/reset').then(() => {
        this.$router.replace({ name: 'outro' });
      });
    },
    submit() {
      window.open('https://www.ourworldirishaidawards.ie/form-submission/');
    },
    enter(el, done) {
      this.$root.$emit('hide-sunshine-background');
      this.$root.$emit('show-primary-logo');
      this.$root.$emit('show-secondary-logo-inverted');

      // Add entering animations
      gsap
        .timeline({
          onComplete: done,
          delay: 0.5,
        })
        .fromTo(
          this.$refs.speechBubble,
          {
            opacity: 0,
            y: '200%',
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            ease: 'power1',
          },
          0.25
        )
        .fromTo(
          this.$refs.character,
          {
            opacity: 0,
            y: '200%',
          },
          {
            opacity: 1,
            y: 0,
            x: 0,
            duration: 0.5,
            ease: 'power1',
          },
          0.25
        );

      // Jangle the button
      this.buttonJangleTimeline = gsap
        .timeline({
          repeat: -1,
          repeatDelay: 6,
          delay: 6,
        })
        .fromTo(
          this.$refs.startButton,
          0.1,
          {
            rotate: '-3',
          },
          {
            rotate: 3,
            repeat: 5,
            yoyo: true,
            ease: 'power1.out',
          }
        );
    },
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.speechBubble,
          {
            opacity: 1,
            y: 0,
          },
          {
            opacity: 0,
            y: '-200%',
            duration: 0.5,
            ease: 'power1.out',
          },
          0
        )
        .fromTo(
          this.$refs.character,
          {
            opacity: 1,
            y: 0,
          },
          {
            opacity: 0,
            y: '200%',
            duration: 0.5,
            ease: 'power1.out',
          },
          0
        );
    },
  },
};
</script>

<style scoped></style>
