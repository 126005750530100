<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <RouteWrapper>
      <section class="container mx-auto z-10 flex justify-center">
        <div
          class="flex flex-col justify-center items-center w-10/12 md:w-6/12 lg:w-5/12 lg:transform lg:transform-gpu lg:-translate-x-1/2"
        >
          <img
            ref="titleImage"
            class="h-auto mb-4 lg:mb-10 lg:w-144"
            src="~@/assets/images/future-news-logo.svg"
          />
          <div ref="startButton">
            <Button @click="start()" :size="'large'">Start</Button>
          </div>
        </div>
        <img
          ref="character"
          class="absolute -bottom-6 z-10 right-0 h-80 sm:h-64 md:h-96 lg:h-160 xl:h-176"
          src="~@/assets/images/character-a.png"
        />
      </section>
    </RouteWrapper>
  </transition>
</template>

<script>
import { gsap } from 'gsap';
import Button from '@/components/Button';
import RouteWrapper from '@/components/RouteWrapper';

export default {
  components: {
    Button,
    RouteWrapper,
  },
  methods: {
    start() {
      this.$router.replace({ name: 'intro' });
    },
    enter(el, done) {
      this.$root.$emit('show-secondary-logo-inverted');

      // Add entering animations
      gsap
        .timeline({
          onComplete: done,
          delay: 0.5,
        })
        .fromTo(
          this.$refs.character,
          {
            y: '200%',
          },
          {
            y: 0,
            duration: 0.5,
            ease: 'power1',
          },
          0.25
        )
        .fromTo(
          this.$refs.titleImage,
          {
            y: '40px',
            autoAlpha: 0,
          },
          {
            y: 0,
            duration: 0.25,
            autoAlpha: 1,
          }
        )
        .fromTo(
          this.$refs.startButton,
          {
            y: '40px',
            autoAlpha: 0,
          },
          {
            y: 0,
            duration: 0.25,
            autoAlpha: 1,
          }
        );

      // Jangle the button
      this.buttonJangleTimeline = gsap
        .timeline({
          repeat: -1,
          repeatDelay: 6,
          delay: 6,
        })
        .fromTo(
          this.$refs.startButton,
          0.1,
          {
            rotate: '-3',
          },
          {
            rotate: 3,
            repeat: 5,
            yoyo: true,
            ease: 'power1.out',
          }
        );
    },
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.character,
          {
            y: 0,
          },
          {
            y: '200%',
            duration: 0.5,
          },
          0
        )
        .fromTo(
          this.$refs.titleImage,
          {
            y: 0,
            autoAlpha: 1,
          },
          {
            y: '-40px',
            autoAlpha: 0,
            duration: 0.5,
          },
          0
        )
        .fromTo(
          this.$refs.startButton,
          {
            y: 0,
            autoAlpha: 1,
          },
          {
            y: '-40px',
            autoAlpha: 0,
            duration: 0.5,
          },
          0
        );
    },
  },
};
</script>

<style scoped></style>
