<template>
  <button
    :class="styles"
    class="button relative block border-0 bg-transparent cursor-pointer text-white leading-none font-stanton no-underline outline-none transition-all duration-100 ease-out"
    @click="buttonDown($event)"
  >
    <span
      class="relative block z-10 transition-transform ease-out duration-150"
    >
      <slot></slot>
    </span>
    <svg
      class="absolute top-1/2 left-1/2 w-full z-0 transform -translate-y-2/4 -translate-x-2/4"
      clip-rule="evenodd"
      fill-rule="evenodd"
      stroke-linejoin="round"
      stroke-miterlimit="2"
      viewBox="0 0 2467 868"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m190.05 32.977c77.707-35.913 173.669-34.388 263.938-31.8 553.45 15.875 1106.91 31.756 1660.36 47.631 67.925 1.956 141.631 5.594 192.994 40.306 60.5 40.888 69.475 110.294 74.181 172.619 8.581 113.594 17.156 227.187 25.738 340.781 4.587 60.731 5.375 129.631-50.294 172.256-48.313 36.988-122.788 41.032-190.469 42.675-631.206 15.325-1263.21 10.388-1893.89-14.8-81.082-3.231-174.507-9.231-230.163-61.656-55.794-52.544-47.375-128.931-26.681-190.775 14.737-44.006 46.006-84.231 52.081-129.95 9.344-70.262-25.9-138.444-13.569-209.531 12.144-69.938 55.675-140.744 135.775-177.756"
        fill="#fff"
      />
      <path
        d="m247.423 73.95c77.713-35.913 173.669-34.388 263.938-31.8 553.45 15.881 1106.91 31.756 1660.36 47.631 67.931 1.956 141.631 5.594 193 40.306 60.5 40.888 69.469 110.294 74.175 172.619 8.581 113.594 17.163 227.187 25.744 340.781 4.581 60.731 5.375 129.638-50.294 172.256-48.319 36.988-122.794 41.032-190.475 42.682-631.2 15.318-1263.21 10.381-1893.88-14.8-81.088-3.238-174.506-9.238-230.169-61.663-55.794-52.544-47.375-128.931-26.681-190.775 14.737-44.006 46.006-84.231 52.087-129.95 9.344-70.262-25.906-138.444-13.568-209.531 12.137-69.938 55.675-140.744 135.768-177.756"
        :fill="svgColor"
      />
    </svg>
  </button>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'pink',
    },
    size: {
      type: String,
      default: 'regular',
    },
  },
  data() {
    const sound = new Audio('/sounds/button.mp3');
    return {
      sound,
    };
  },
  computed: {
    styles() {
      return {
        small: 'px-8 md:px-10 py-8 text-xl lg:text-3xl',
        regular: 'px-8 md:px-12 py-8 text-2xl lg:text-4xl',
        large: 'px-12 md:px-20 py-6 text-3xl lg:text-5xl',
      }[this.size];
    },
    svgColor() {
      return {
        pink: '#dc1768',
        orange: '#f16a2c',
        navy: '#000b4a',
        green: '#5bb947',
        'bright-blue': '#2793d1',
      }[this.color];
    },
  },
  methods: {
    buttonDown($event) {
      this.sound.play();
      this.$emit('click', $event);
      $event.preventDefault();
    },
  },
};
</script>

<style scoped>
.button {
  min-width: 125px;
}

.button:hover {
  transform: scale(1.05) rotate(-3deg) translate3d(0, -2px, 0);
}

.button:hover .button__content {
  transform: scale(1.05);
}

.button:active {
  top: -3px;
  left: -3px;
}
</style>
