const defaultState = () => {
  const state = {
    questions: [
      {
        id: 1,
        label:
          '<strong>Noun (thing)</strong> &mdash; crazy name for a news programme',
      },
      {
        id: 2,
        label: '<strong>Number</strong> &mdash; between 23 and 99',
      },
      {
        id: 3,
        label:
          '<strong>Noun (place)</strong> &mdash; country where Irish Aid works',
      },
      {
        id: 4,
        label:
          '<strong>Exclamation</strong> &mdash; to show suprise or excitement, like "whoa" or "woo-hoo"',
      },
      {
        id: 5,
        label:
          '<strong>Time of the day</strong> &mdash; morning/afternoon/evening',
      },
      {
        id: 6,
        label: '<strong>Noun (person)</strong> &mdash; silly name',
      },
      {
        id: 7,
        label: '<strong>Plural noun (thing)</strong> &mdash; type of food',
      },
      {
        id: 8,
        label: '<strong>Noun (thing)</strong> &mdash; name of a meal',
      },
      {
        id: 9,
        label: '<strong>Plural noun (thing)</strong> &mdash; job title',
      },
      {
        id: 10,
        label:
          '<strong>Noun (place)</strong> &mdash; name of a building where people go when they are sick',
      },
      {
        id: 11,
        label:
          '<strong>Plural Noun (thing)</strong> &mdash; item that can be found in a classroom',
      },
      {
        id: 12,
        label:
          '<strong>Noun (thing)</strong> &mdash; sport played with two or more people',
      },
      {
        id: 13,
        label: '<strong>Adjective</strong> &mdash; to describe a sport',
      },
      {
        id: 14,
        label:
          '<strong>Plural noun (place)</strong> &mdash; landform or type of body of water, for example mountains or oceans',
      },
      {
        id: 15,
        label:
          '<strong>Noun (thing)</strong> &mdash; item that is bad for the environment',
      },
      {
        id: 16,
        label:
          '<strong>Plural Noun (thing)</strong> &mdash; item of clothing or footwear',
      },
      {
        id: 17,
        label:
          '<strong>Plural noun (thing)</strong> &mdash; type of transportation',
      },
      {
        id: 18,
        label:
          '<strong>Noun (person or thing)</strong> &mdash; person or group with responsibility to help make the Global Goals happen',
      },
      {
        id: 19,
        label:
          '<strong>Exclamation</strong> &mdash; to show alarm or concern, like "Yikes" or "Uh-oh"',
      },
      {
        id: 20,
        label: '<strong>Adjective</strong> &mdash; to describe a noise',
      },
    ],
    pages: [
      {
        text: "It's my first day on the job as a time travelling reporter for [[1]].  I've travelled through time to the year 20[[2]], landing with a bit of a bump in what appears to be [[3]].",
      },
      {
        text: "[[4]] hang on, I'm about to go live on air ...",
        sound: '/sounds/news-intro.mp3',
        delay: 1,
      },
      {
        text: "“Good [[5]] everyone.  My name is [[6]] and I'm coming to you live with breaking news from our future.",
      },
      {
        text: "I know it's hard to imagine, but no one in our future world lives in poverty. There is enough clean water to drink and good food to eat for everyone!",
        sound: '/sounds/drink.mp3',
        delay: 1,
      },
      {
        text: 'I tried the [[7]] for [[8]] and they were delicious! Believe me! There was no food waste at all!',
        sound: '/sounds/eating.mp3',
        delay: 1,
      },
      {
        text: 'Everyone has equal opportunities, and everyone feels safe and respected. For example, equal numbers of girls and boys are [[9]].',
        sound: '/sounds/yay.mp3',
      },
      {
        text: 'Fewer children get sick and those that do can go to a [[10]] and get the medicine that they need.',
      },
      {
        text: 'All children get a chance to go to and finish school and they have all the [[11]] that they need to learn.',
        sound: '/sounds/children.mp3',
      },
      {
        text: "A really popular thing to do in your spare time is play [[12]]. It's [[13]]!",
        sound: '/sounds/clapping.mp3',
      },
      {
        text: 'People love to meet outside because the air is clean and [[14]] are unpolluted.',
        sound: '/sounds/birds.mp3',
      },
      {
        text: "Lots of care is taken not to use up too much of our earth's resources.  For example, people don't buy [[15]] anymore.  They only wear [[16]] that have been made from sustainable materials.  Everyone uses environmentally friendly [[17]]. ",
        sound: '/sounds/bicycle.mp3',
      },
      {
        text: 'It is clear that [[18]] work(s) hard for the wellbeing of all people and our planet.',
      },
      {
        text: "[[19]]!  We seem to be experiencing some trouble with our connection ... I can hear a very [[20]] noise. I'd better wrap it up. Tune in again for another live breaking news report from our better, future world!",
        sound: '/sounds/hiss.mp3',
      },
    ],
    answers: [],
  };
  state['answers'] = state['questions'].map(() => '');
  return state;
};

const state = defaultState();

const getters = {
  questions: (state) => {
    return state.questions;
  },
  answers: (state) => {
    return state.answers;
  },
  pages: (state) => {
    return state.pages;
  },
};

const actions = {
  addAnswer({ commit }, { index, value }) {
    commit('addAnswer', { index, value });
  },
  reset({ commit }) {
    commit('reset');
  },
};

const mutations = {
  addAnswer(state, { index, value }) {
    // You cannot update an array via arr[index] = 'foo' or Vue will not detect
    // the change when accessing via computed property. To get around this we
    // create a completely new array and replace it.
    const arr = state.answers.slice(0, state.answers.length);
    arr[index] = value;
    state.answers = arr;
  },
  reset(state) {
    Object.assign(state, defaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
