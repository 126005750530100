const debounce = function (fnBefore, fnAfter, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    fnBefore.apply(that, args);
    timeoutID = setTimeout(function () {
      fnAfter.apply(that, args);
    }, delay);
  };
};

// https://stackabuse.com/how-to-split-an-array-into-even-chunks-in-javascript/
const chunk = function (arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export { debounce, chunk };
