<template>
  <div
    class="clock absolute right-0 top-0 h-full bg-ia-pink px-3 py-3 text-white text-base md:text-xl lg:text-5xl font-din-pro font-bold flex justify-center items-center"
  >
    <span>{{ time }}</span>
    <img
      class="svg-border absolute left-0 top-0 h-full"
      src="@/assets/images/clock-border.svg"
      alt="-"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      time: '00:00',
    };
  },
  methods: {
    updateTime() {
      const d = new Date();
      const dd = [d.getHours(), d.getMinutes()].map((a) =>
        a < 10 ? '0' + a : a
      );
      this.time = dd.join(':');
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.updateTime();
    }, 1000);
    this.updateTime();
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.clock {
  transform: translateY(-5%) scale(1.1);
}

.svg-border {
  transform: translate3d(-90%, 0, 0);
}
</style>
