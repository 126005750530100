<template>
  <div
    class="absolute top-0 left-0 h-full w-full flex justify-center items-center"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
