<template>
  <transition appear :css="false">
    <div
      class="bg-ia-light-blue relative h-full w-full max-w-full max-h-full flex justify-center items-center bg-white"
    >
      <div ref="logoContent" class="loading__content">
        <img
          @load="logoLoaded"
          class="w-full"
          src="~@/assets/images/our-world-logo-white.svg"
        />
        <p
          class="font-stanton text-4xl w-full text-white mt-2 text-center leading-none"
        >
          Loading ...
        </p>
      </div>
      <img
        ref="background"
        @load="backgroundLoaded"
        class="opacity-0 absolute top-0 left-0 w-full h-full z-0 object-cover transition-opacity duration-1000 ease-linear"
        src="~@/assets/images/world-background.svg"
      />
    </div>
  </transition>
</template>

<script>
/**
 * Loading Route
 *
 * An initial full-screen loading route that pre-loads all of the assets before
 * the game can be played, for better UX.
 */
import { gsap } from 'gsap';
export default {
  created() {
    const images = [
      'blue-sky-background.jpg',
      'character-a.png',
      'character-b.png',
      'character-c.png',
      'character-d.png',
      'clock-border.svg',
      'cloud-1.png',
      'cloud-2.png',
      'cloud-background-top.png',
      'cloud-background.png',
      'down-arrow.png',
      'favicon.png',
      'future-news-logo.svg',
      'hot-air-1.png',
      'hot-air-2.png',
      'live-text.svg',
      'our-world-logo-colour.png',
      'our-world-logo-white.svg',
      'sun.png',
      'sunshine-background.jpg',
      'sunshine-background.png',
      'up-arrow.png',
      'world-background.svg',
    ].map((fileName) => {
      return new Promise((resolve, reject) => {
        let img = new Image();
        let url = require(`@/assets/images/${fileName}`);
        img.onload = () => {
          resolve(true);
        };
        img.src = url;
      });
    });
    Promise.all(images).then(() => {
      setTimeout(() => {
        this.$router.replace({ name: 'title' });
      }, 2000);
    });
  },
  beforeRouteLeave(to, from, next) {
    gsap
      .timeline({
        onComplete: next,
      })
      .fromTo(
        this.$refs.logoContent,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 1,
        }
      );
  },
  methods: {
    logoLoaded() {
      gsap.timeline().fromTo(
        this.$refs.logoContent,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
        }
      );
    },
    backgroundLoaded() {
      gsap.timeline().fromTo(
        this.$refs.background,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
        }
      );
    },
  },
};
</script>

<style scoped>
@keyframes pulse {
  0% {
    transform: scale(0.95) rotate(-3deg);
  }

  50% {
    transform: scale(1) rotate(-3deg);
  }

  100% {
    transform: scale(0.95) rotate(-3deg);
  }
}

.loading__content {
  transform: scale(1) rotate(-3deg);
  animation: pulse 2s infinite;
  z-index: 5;
  opacity: 0;
  max-width: 600px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
