<template>
  <transition appear :css="false">
    <div class="relative" ref="bubble">
      <div class="foreground" :style="'background-color: ' + colorMapped">
        <slot></slot>
      </div>
      <svg
        class="background"
        :class="{ 'background--flipped': flip }"
        clip-rule="evenodd"
        fill-rule="evenodd"
        stroke-linejoin="round"
        stroke-miterlimit="2"
        viewBox="0 0 4564 3490"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m4399.75 1308.84c-331.112-923.594-1205.25-1323.37-2022.44-1308.44-418.338 7.631-864.7 96.275-1224.99 366.206-262.206 196.444-280.3 254.069-612.763 813.006-94.975 159.656-545.137 527.138-539.5 723.331 4.788 167.707 453.7-250.631 577.238 284.15 394.794 1248.41 1660 1256.11 1664.04 1241.64 533.437 3.556 857.587-70.156 1360.33-294.575 294.843-131.681 602.562-332.437 769.356-660.069 183.544-360.462 162.406-792.475 28.737-1165.26"
          fill="#fff"
        />
        <path
          d="m4458.98 1366.69c-331.112-923.593-1205.25-1323.37-2022.45-1308.44-418.337 7.625-864.7 96.275-1224.99 366.206-262.206 196.438-280.3 254.069-612.762 813.006-94.975 159.657-545.131 527.138-539.494 723.332 4.781 167.7 453.694-250.638 577.231 284.15 394.794 1248.41 1660 1256.11 1664.04 1241.64 533.438 3.557 857.588-70.156 1360.33-294.575 294.844-131.681 602.563-332.437 769.356-660.068 183.55-360.463 162.413-792.482 28.744-1165.26"
          :fill="colorMapped"
        />
      </svg>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'green',
    },
    flip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorMapped() {
      return {
        orange: '#f16a2c',
        pink: '#dc1768',
        navy: '#000b4a',
        'light-blue': '#2793d1',
        green: '#5bb947',
      }[this.color];
    },
  },
};
</script>

<style scoped>
.foreground {
  @apply w-full h-full flex flex-col justify-center items-center;
  padding: 30px 20px;
  border-radius: 30px;
  box-shadow: -10px -10px 0px 0px rgba(255, 255, 255, 1);
}

@screen xl {
  .foreground {
    @apply w-full h-full flex flex-col justify-center items-center;
    background: none !important;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }
}

.background {
  @apply hidden absolute h-auto bottom-1/2 left-1/2;
  width: 150%;
  z-index: -1;
  transform: scaleY(-1) translate3d(-55%, -50%, 0) rotate(6deg);
}

.background--flipped {
  @apply absolute h-auto top-auto bottom-1/2 right-1/2 left-auto;
  transform: scaleY(-1) scaleX(-1) translate3d(-55%, -50%, 0) rotate(6deg);
}

@screen xl {
  .background {
    @apply block;
  }
}
</style>
