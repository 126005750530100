<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <RouteWrapper>
      <section class="container mx-auto z-10 flex justify-center">
        <div
          class="w-10/12 md:w-6/12 lg:w-5/12 lg:transform-gpu lg:-translate-x-1/2"
        >
          <div ref="speechBubble">
            <SpeechBubble :flip="true">
              <div class="text-white font-din-pro text-center mb-3 md:mb-10">
                <h1 class="text-xl md:text-4xl font-bold mb-3 md:mb-5">
                  You've completed the Future News Story Game!
                </h1>
                <h2 class="text-lg md:text-3xl">
                  Would you like to play again?
                </h2>
              </div>
              <div ref="startButton">
                <Button @click="start()">Play Again</Button>
              </div>
            </SpeechBubble>
          </div>
        </div>
        <img
          ref="character"
          class="absolute bottom-0 z-10 right-0 h-80 sm:h-64 md:h-96 lg:h-160 xl:h-176"
          src="~@/assets/images/character-c.png"
        />
      </section>
    </RouteWrapper>
  </transition>
</template>

<script>
import { gsap } from 'gsap';
import Button from '@/components/Button';
import RouteWrapper from '@/components/RouteWrapper';
import SpeechBubble from '@/components/SpeechBubble';

export default {
  components: {
    Button,
    RouteWrapper,
    SpeechBubble,
  },
  methods: {
    start() {
      this.$store.dispatch('story/reset').then(() => {
        this.$router.replace({ name: 'intro' });
      });
    },
    enter(el, done) {
      this.$root.$emit('hide-sunshine-background');
      this.$root.$emit('show-primary-logo');
      this.$root.$emit('show-secondary-logo-inverted');

      // Add entering animations
      gsap
        .timeline({
          onComplete: done,
          delay: 0.5,
        })
        .fromTo(
          this.$refs.speechBubble,
          {
            opacity: 0,
            y: '200%',
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            ease: 'power1',
          },
          0.25
        )
        .fromTo(
          this.$refs.character,
          {
            opacity: 0,
            y: '200%',
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            ease: 'power1',
          },
          0.25
        );

      // Jangle the button
      this.buttonJangleTimeline = gsap
        .timeline({
          repeat: -1,
          repeatDelay: 6,
          delay: 6,
        })
        .fromTo(
          this.$refs.startButton,
          0.1,
          {
            rotate: '-3',
          },
          {
            rotate: 3,
            repeat: 5,
            yoyo: true,
            ease: 'power1.out',
          }
        );
    },
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.speechBubble,
          {
            opacity: 1,
            y: 0,
          },
          {
            opacity: 0,
            y: '-200%',
            duration: 0.5,
            ease: 'power1.out',
          },
          0
        )
        .fromTo(
          this.$refs.character,
          {
            opacity: 1,
            y: 0,
          },
          {
            opacity: 0,
            y: '200%',
            duration: 0.5,
            ease: 'power1.out',
          },
          0
        );
    },
  },
};
</script>

<style scoped></style>
