<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <RouteWrapper>
      <section
        ref="clouds"
        class="bg-ia-cloud z-10 fixed h-auto w-full bottom-0 left-0 flex flex-col pb-10 lg:pb-0"
      >
        <img
          src="@/assets/images/cloud-background-top.png"
          class="z-10 absolute w-full top-0 left-0 transform -translate-y-full"
        />
        <div
          class="min-w-full overflow-hidden flex flex-row transition transition-transform duration-300 ease-in-out"
          :style="containerStyles"
        >
          <div
            v-for="(page, i) in replacedPages"
            :key="i"
            class="w-full flex justify-start lg:justify-center items-center px-3 md:px-6 py-4"
          >
            <div
              class="font-din-pro text-sm sm:text-lg md:text-xl lg:text-2xl leading-relaxed w-11/12 md:w-10/12 lg:w-6/12 font-medium text-center"
              v-html="page"
            ></div>
          </div>
        </div>
        <div
          class="absolute bottom-2 lg:bottom-1/2 right-1/2 lg:right-10 transform lg:translate-y-1/2"
        >
          <Button
            class="hidden lg:block"
            :color="'pink'"
            :size="'regular'"
            @click="next()"
            >Next</Button
          >
          <button
            class="lg:hidden text-ia-pink font-stanton text-2xl"
            @click="next()"
          >
            Next
          </button>
        </div>
      </section>
    </RouteWrapper>
  </transition>
</template>

<script>
/**
 * Story Route
 *
 * This route reads back the story to the user after they have enter all of their
 * input. There are multiple "pages" on this route, like a slider.
 */
import RouteWrapper from '@/components/RouteWrapper';
import { mapGetters } from 'vuex';
import { gsap } from 'gsap';
import Button from '@/components/Button';

export default {
  data() {
    return {
      pageIndex: 0,
    };
  },
  components: {
    RouteWrapper,
    Button,
  },
  methods: {
    enter(el, done) {
      this.$root.$emit('show-sunshine-background');
      this.$root.$emit('show-primary-logo');
      this.$root.$emit('show-secondary-logo');

      gsap
        .timeline({
          onComplete: done,
          delay: 1,
        })
        .fromTo(
          this.$refs.clouds,
          {
            y: '300%',
          },
          {
            y: 0,
            duration: 0.5,
            ease: 'power1',
          }
        );
    },
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.clouds,
          {
            y: '0',
          },
          {
            y: '300%',
            duration: 0.25,
            ease: 'power1',
          }
        );
    },
    next() {
      let currentPage = this.pages[this.pageIndex];
      if (this.pageIndex + 1 < this.pages.length) {
        this.pageIndex++;
        let nextPage = this.pages[this.pageIndex];
        if (nextPage.sound) {
          if (nextPage.delay) {
            setTimeout(() => {
              new Audio(nextPage.sound).play();
            }, nextPage.delay);
          } else {
            new Audio(nextPage.sound).play();
          }
        }
      } else {
        new Audio('/sounds/news-intro.mp3').play();
        this.$router.replace({ name: 'headline' });
      }
    },
  },
  computed: {
    ...mapGetters('story', {
      questions: 'questions',
      answers: 'answers',
      pages: 'pages',
    }),
    showNext() {
      return this.pageIndex >= 0 && this.pageIndex < this.pages.length - 1;
    },
    containerStyles() {
      const width = 100 * this.pages.length;
      const offset = -1 * (100.0 / this.pages.length) * this.pageIndex;
      return {
        width: `${width}%`,
        transform: `translate3d(${offset}%, 0, 0)`,
      };
    },
    replacedPages() {
      const regex = /\[\[(\d+)\]\]/gi;
      return this.pages.map((page) => {
        const text = page.text;
        const matches = text.match(regex);
        if (matches === null) {
          return text;
        } else {
          return text.match(regex).reduce((str, match) => {
            const index = match.slice(2, match.length - 2);
            return str.replace(
              match,
              `<strong class='text-ia-pink'>${this.answers[index - 1]}</strong>`
            );
          }, text);
        }
      });
    },
  },
};
</script>

<style scoped>
.cloud-background {
  background-image: url('~@/assets/images/cloud-background.png');
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}
</style>
