import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import story from './modules/story';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const plugins = [];

if (debug) {
  plugins.push(Vuex.createLogger());
}

export default new Vuex.Store({
  modules: {
    story,
  },
  strict: debug,
  plugins,
});
