<template>
  <transition appear :css="false">
    <div class="relative">
      <div class="foreground" :style="'background-color: ' + colorMapped">
        <slot></slot>
      </div>
      <svg
        class="background"
        clip-rule="evenodd"
        fill-rule="evenodd"
        stroke-linejoin="round"
        stroke-miterlimit="2"
        viewBox="0 0 6999 3933"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m748.954 3856.41c-139.644 2.1-286.75 2.919-408.988-46.881-214.168-87.257-272.225-291.525-295.787-471.019-51.356-391.188-46.231-785.075-41.063-1178.06 7.913-601.168 15.819-1202.34 23.732-1803.5 1.062-80.831 4.593-167.468 68.906-232.956 98.131-99.919 291.6-109.619 458.981-110.006 2005.14-4.656 4010.29-9.319 6015.43-13.975 97.319-.231 205.088 2.444 275.65 51.837 81.45 57.019 79.494 151.432 71.694 234.019-93.906 993.631-187.812 1987.26-281.719 2980.89-12.975 137.275-30.3 283.362-144.206 392.4-104.262 99.806-275.644 151.719-445.544 165.462-151.918 12.294-308.318.744-456.831-24.268-138.719-23.369-283.125-12.538-424.256-10.413-355.894 5.356-711.788 10.713-1067.69 16.075-358.312 5.388-716.631 10.781-1074.94 16.175-355.894 5.356-711.788 10.713-1067.69 16.075-358.312 5.388-716.625 10.781-1074.94 16.181-43.581.65-87.156 1.313-130.737 1.963"
          fill="#fff"
        />
        <path
          d="m826.736 3931.57c-139.644 2.106-286.75 2.918-408.988-46.882-214.168-87.256-272.225-291.518-295.787-471.018-51.356-391.188-46.231-785.069-41.063-1178.06 7.913-601.169 15.819-1202.34 23.732-1803.49 1.062-80.831 4.593-167.475 68.906-232.956 98.131-99.919 291.6-109.625 458.975-110.012 2005.14-4.657 4010.29-9.313 6015.43-13.975 97.325-.232 205.088 2.443 275.65 51.843 81.456 57.013 79.5 151.425 71.7 234.013l-281.719 2980.9c-12.975 137.269-30.3 283.356-144.206 392.394-104.269 99.806-275.644 151.718-445.544 165.462-151.918 12.294-308.325.744-456.831-24.269-138.719-23.362-283.131-12.537-424.256-10.412-355.894 5.362-711.788 10.712-1067.69 16.075-358.312 5.394-716.625 10.781-1074.94 16.175-355.894 5.362-711.788 10.712-1067.69 16.075-358.312 5.394-716.631 10.781-1074.94 16.181-43.581.65-87.156 1.313-130.737 1.963"
          :fill="colorMapped"
        />
      </svg>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'green',
    },
  },
  computed: {
    colorMapped() {
      return {
        orange: '#f16a2c',
        pink: '#dc1768',
        navy: '#000b4a',
        'light-blue': '#2793d1',
        green: '#5bb947',
      }[this.color];
    },
  },
};
</script>

<style scoped>
.foreground {
  @apply w-full h-full flex flex-col justify-center items-center;
  padding: 30px 20px;
  border-radius: 30px;
  box-shadow: -10px -10px 0px 0px rgba(255, 255, 255, 1);
}

@screen xl {
  .foreground {
    @apply w-full h-full flex flex-col justify-center items-center;
    background: none !important;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }
}

.background {
  @apply hidden absolute h-auto top-1/2 left-1/2;
  width: 110%;
  z-index: -1;
  transform: translate3d(-50%, -50%, 0);
}

@screen xl {
  .background {
    @apply block absolute h-auto top-1/2 left-1/2;
  }
}
</style>
