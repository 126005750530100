<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <div class="absolute w-full h-full top-0 left-0">
      <img
        class="absolute top-0 left-0 w-full h-full z-0 object-cover transition-opacity duration-1000 ease-linear"
        src="~@/assets/images/world-background.svg"
      />
    </div>
  </transition>
</template>
<script>
import { gsap } from 'gsap';

export default {
  methods: {
    enter(el, done) {},
    leave(el, done) {},
  },
};
</script>

<style scoped></style>
