<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <RouteWrapper>
      <section class="container mx-auto z-10 flex justify-center">
        <div
          ref="content"
          class="z-10 flex flex-col justify-center items-center w-10/12 md:w-6/12 lg:w-5/12"
        >
          <SquareBubble>
            <div class="text-white font-din-pro text-center">
              <h2 class="font-bold text-xl md:text-2xl lg:text-3xl">
                What will the world be like when the Global Goals have been
                achieved?
              </h2>
              <p class="mt-3 text-base md:text-xl">
                Test your language skills and tell us what you think life will
                be like in the future! Fill in the blanks in the boxes, and then
                press 'Read' to see your story.
              </p>
              <p class="mt-3 text-base md:text-xl">
                Press 'Play' to give it a go!
              </p>
            </div>
          </SquareBubble>
          <div class="z-10 mt-6 md:mt-12 lg:mt-32" ref="startButton">
            <Button @click="play()" :size="'large'">Play</Button>
          </div>
        </div>
        <img
          ref="character"
          class="absolute z-0 right-0 md:right-12 bottom-0 md:bottom-12 h-80 sm:h-64 md:h-96"
          src="~@/assets/images/character-d.png"
        />
      </section>
    </RouteWrapper>
  </transition>
</template>

<script>
/**
 * Intro Route
 *
 * This route explains the game to the user and offers a number of links for
 * further reading on the subject.
 */
import Button from '@/components/Button';
import RouteWrapper from '@/components/RouteWrapper';
import SquareBubble from '@/components/SquareBubble';
import { gsap } from 'gsap';

export default {
  components: {
    Button,
    RouteWrapper,
    SquareBubble,
  },
  methods: {
    play() {
      this.$store.dispatch('story/reset').then(() => {
        this.$router.replace({ name: 'write' });
      });
    },
    enter(el, done) {
      this.$root.$emit('show-primary-logo');
      this.$root.$emit('show-secondary-logo-inverted');
      gsap
        .timeline({
          onComplete: done,
          delay: 0.5,
        })
        .fromTo(
          this.$refs.character,
          {
            opacity: 0,
            y: '200%',
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            ease: 'power1.out',
          },
          0
        )
        .fromTo(
          this.$refs.content,
          {
            opacity: 0,
            y: '40px',
            autoAlpha: 0,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            autoAlpha: 1,
            ease: 'power1.out',
          },
          0.5
        );
    },
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.character,
          {
            opacity: 1,
            y: 0,
          },
          {
            opacity: 0,
            y: '200%',
            duration: 0.5,
            ease: 'back.in(.8)',
          },
          0
        )
        .fromTo(
          this.$refs.content,
          {
            opacity: 1,
            y: 0,
          },
          {
            opacity: 0,
            y: '-200%',
            duration: 0.5,
            ease: 'back.in(.8)',
          },
          0
        );
    },
  },
};
</script>

<style scoped></style>
